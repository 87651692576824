// collections.js
export const collectionNames = [
  "BBX",
  "Crafty Canines",
  "Moodz",
  "Onyx",
  "Seiberians",
  "Warp Bois",
  "dob"
];
